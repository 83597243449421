import React, { useEffect, useState } from 'react';
import type { GetStaticProps } from 'next';
import { useRouter } from 'next/router';
import { marked } from 'marked';
import { HomepageContentQuery } from '@/queries/PageContentQuery';
import { fetchGraphQL } from '@/queries/fetchGraphQL';
import { SEO } from '@/components/SEO';
import { Footer } from '@/components/Footer';
import { Navigation } from '@/components/Navigation';
import { BlockRenderer } from '@/components/BlockRenderer';
import { Legal } from '@/components/Legal';
import { Mode } from '@/constants/Mode';
import { getCachedRates } from '../logic/getCachedRates';
import type { NextPageWithLayout, Page } from '../types/layoutExtensions';
import { useScrollAnchorIntoView } from '../utilities/useScrollAnchorIntoView';

export const processMarkdown = async (obj: any) => {
  Object.keys(obj).forEach(async (key) => {
    if (key.includes('markdown') && obj[key]) {
      const dom = marked.parse(obj[key]);
      // eslint-disable-next-line no-param-reassign
      obj[key] = dom;
    }
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      processMarkdown(obj[key]);
    }
  });
};

const Index: NextPageWithLayout = (page) => {
  useScrollAnchorIntoView();

  const { rates, pageCollection } = page;
  const router = useRouter();
  const isPreview = router.asPath.split('?preview=')[1] === 'true' || false;
  const [data, setData] = useState<Page | null>(null);
  useEffect(() => {
    const queryData = async (isPreviewMode: boolean) => {
      const { data: newData }: any = await fetchGraphQL(HomepageContentQuery(router.pathname), isPreviewMode);
      processMarkdown(newData.pageCollection.items[0]);
      setData(newData);
    };
    if ((process.env.NEXT_PUBLIC_ENV === 'release' || process.env.NEXT_PUBLIC_ENV === 'develop') && isPreview) {
      queryData(isPreview);
    }
  }, [isPreview, router.pathname]);

  let { navigationMode, contentCollection, legal } = pageCollection.items[0];

  if (data !== null) {
    navigationMode = data.pageCollection.items[0].navigationMode;
    contentCollection = data.pageCollection.items[0].contentCollection;
    legal = data.pageCollection.items[0].legal;
  }

  return (
    <>
      <Navigation mode={navigationMode as Mode} />
      <main id="skip-to-content" style={{ outline: 'none' }} tabIndex={-1}>
        {contentCollection?.items.map((contentBlock, index) => (
          <BlockRenderer {...contentBlock} key={index} rates={rates} />
        ))}
        {legal && legal.legalHeading && (
          <Legal
            legalHeading={legal.legalHeading}
            legalComparisonBlock={legal.legalComparisonBlock}
            accordians={legal.accordiansCollection.items[0]}
          />
        )}
      </main>
      <Footer />
    </>
  );
};

export const getStaticProps: GetStaticProps = async () => {
  const { data }: any = await fetchGraphQL(HomepageContentQuery('/'), false);
  processMarkdown(data.pageCollection.items[0]);
  const page: any = data;
  page.rates = await getCachedRates();
  return {
    props: page,
  };
};

Index.getLayout = (page: React.ReactElement) => {
  const {
    seoTitle, seoDescription, ldJsonSchema, ogImage,
  } = page.props.children[1].props.pageCollection.items[0];
  return (
    <>
      <SEO
        {...{ seoTitle, seoDescription, ldJsonSchema }}
        {...(ogImage ? { ogImageUrl: ogImage.url, ogImageWidth: ogImage.width, ogImageHeight: ogImage.height } : {})}
        fullPath=""
      />
      <div className="page">{page}</div>
    </>
  );
};

export default Index;
