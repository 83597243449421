export async function fetchGraphQL(query: string, isPreview: boolean) {
  return fetch(
    `https://graphql.contentful.com/content/v1/spaces/${process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID}/environments/${process.env.NEXT_PUBLIC_CONTENTFUL_ENVIRONMENT}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: isPreview ? `Bearer ${process.env.NEXT_PUBLIC_CONTENTFUL_PREVIEW_TOKEN}` : `Bearer ${process.env.PUBLIC_CONTENTFUL_DELIVERY_TOKEN}`,
      },
      body: JSON.stringify({ query }),
    },
  )
    .then(async (response) => response.json())
    .catch((err) => {
      // eslint-disable-next-line no-console
      console.error(err);
      throw err;
    });
}
