import gqlmin from 'gqlmin';

export function PageContentQuery(url: string) {
  return /* GraphQL */ `
  query pageQuery {
    pageCollection(where: { url: "${url}" }, limit: 1) {
      items {
        seoTitle
        seoDescription
        ogImage {
          url
          width
          height
          title
        }
        ldJsonSchema
        theme
        sys {
          id
        }
        banner {
          __typename
          ...genericBanner
          ...productBanner
        }
        navigationMode
        legal {
          legalHeading
          legalComparisonBlock
          accordiansCollection(limit: 1) {
            items {
              title
              bodyCollection(limit: 10) {
                items {
                  markdown: body
                }
              }
            }
          }
        }
        contentCollection(limit: 15) {
          items {
            ...PageBlock
          }
        }
      }
    }
  }

  fragment genericBanner on GenericBanner {
    textBoxWidth
    title {
      ...titleText
    }
    image {
      url
      width
      height
      title
    }
    imageMobile {
      url
      width
      height
      title
    }
    mode
    ratesVisible
    rateType
    ratesAlignmentMobile
    ratesAlignmentDesktop
    backgroundColor
    backgroundSizeMobile
    backgroundSizeDesktop
    backgroundRepeatMobile
    backgroundRepeatDesktop
    backgroundPositionMobile
    backgroundPositionDesktop
    backgroundImageMobile {
      url
      width
      height
      title
    }
    backgroundImageDesktop {
      url
      width
      height
      title
    }
    eyebrow {
      ...eyebrow
    }
    text {
      ...titleText
    }
    ctaVisible
    ctaAlignment
    ctaType
    cta {
      ...cta
    }
    ctaFunnel {
      ...funnelButton
    }
    trustPilotVisible
    backgroundDesktop
    backgroundMobile
    isVideo
    videoModalCollection(limit: 6) {
      items {
        ...videoModal
      }
    }
    relatedVideoText {
      ...titleText
    }
    showImgOnMobile
    backBtnBg
    backBtnColor
    backText {
      ...titleText
    }
    videoCta {
      ...cta
    }
  }

  fragment productBanner on ProductBanner {
    banner {
      ...genericBanner
    }
    mode
    tabsCollection(limit: 4) {
      items {
        ...productBannerTab
      }
    }
  }

  fragment productBannerTab on ProductBannerTab {
    name
    icon
    contentCollection(limit: 4) {
      items {
        ...productBannerTabContent
      }
    }
    powerUp {
      ...powerup
    }
    eyebrow {
      ...eyebrow
    }
    title {
      ...titleText
    }
    text {
      ...text
    }
    tabMode
    ctaType
    cta {
      ...cta
    }
    bgColor
    ctaVisible
    desktopControlsOrder
    mobileControlsOrder
  }

  fragment productBannerTabContent on ProductBannerTabContent {
    bgColor
    mode
    emoji
    tabName
    eyebrow {
      ...eyebrow
    }
    displayMode
    contentTitle {
      ...titleText
    }
    contentText {
      ...text
    }
    cta {
      ...cta
    }
    ctaType
    ctaVisible
    listCta {
      ...cta
    }
    listCtaType
    listCtaVisible
    listTitle {
      ...titleText
    }
    listText {
      ...text
    }
    list
    desktopImage {
      url
      width
      height
      title
    }
    mobileImage {
      url
      width
      height
      title
    }
    mobileContentDirection
    desktopContentDirection
  }

  fragment powerup on Powerup {
    basicFeatures
    extrasFeatures
    basicTitle
    basicSubtitle
    basicQuestion
    basicCtaText
    featuresTitle
    basicCta {
      ...cta
    }
    extrasTitle
    extrasSubtitle
    extrasQuestion
    extrasCtaText
    extrasCta {
      ...cta
    }
  }

  fragment BannerVideo on BannerVideo {
    src {
      url
      width
      height
    }
    href
    title
    boxVideo
  }

  fragment PageBlock on PageBlock {
    title
    bg
    slantedbg
    horizontalPadding
    desktopTopPadding
    desktopBottomPadding
    mobileTopPadding
    mobileBottomPadding
    backgroundSlant
    container
    sys {
      id
    }
    contentfulMetadata {
      tags {
        id
      }
    }
    content {
      ...accordiansList
      ...FAQS
      ...mediaBlock
      ...cardVariantList
      ...renderComponent
      ...richTextBlock
      ...rateTable
      ...videoContainer
      ...articleBlock
      ...productRateTable
      ...form
      ...updatedHomePageBanner
      __typename
    }
  }

  fragment productRateTable on ProductRateTable {
    tier
    title {
      ...titleText
    }
    subtitle {
      ...titleText
    }
  }

  fragment renderComponent on RenderComponent {
    component
    anchorId
  }

  fragment rateTable on RateTable {
    mode
    repaymentType
    propertyPurpose
    classic
    title {
      ...titleText
    }
    subtitle {
      ...titleText
    }
    id
  }

  fragment richTextBlock on RichTextBlock {
    content {
      json
      links {
        assets {
          block {
            url
            width
            height
            title
            sys {
              id
            }
          }
        }
        entries {
          inline {
            __typename
            ...ResponsiveImageBlockFragment
          }
          block {
            sys {
              id
            }
            __typename
            ...ResponsiveImageBlockFragment
          }
        }
      }
    }
  }

  fragment ResponsiveImageBlockFragment on ResponsiveImageBlock {
    sys {
      id
    }
    title
    fullWidth
    imageAlt
    imageMobile {
      title
      description
      contentType
      fileName
      size
      url
      width
      height
    }
    imageDesktop {
      title
      description
      contentType
      fileName
      size
      url
      width
      height
    }
  }

  fragment mediaBlock on MediaBlock {
    desktopMediaOrder
    desktopTextWidth
    desktopMediaWidth
    desktopJustify
    mobileMediaOrder
    mobileTextWidth
    mobileMediaWidth
    mobileJustify
    textMode
    mediaMode
    text {
      ...textBlock
    }
    image {
      ...imageBlock
    }
    content {
      ...contentBlock
    }
    cards: cardsCollection(limit: 5) {
      cards: items {
        ...card
      }
    }
    tabs: tabsCollection(limit: 5) {
      tabs: items {
        ...tab
      }
    }
    form {
      ...form
    }
    contentBg
    elevation
    id
  }

  fragment tab on Tab {
    tabName
    title {
      ...titleText
    }
    text {
      ...text
    }
    desktopImage {
      url
      width
      height
      title
    }
    mobileImage {
      url
      width
      height
      title
    }
  }

  fragment card on Tile {
    title {
      ...titleText
    }
    text {
      ...text
    }
    image {
      url
      width
      height
      title
    }
    href
  }

  fragment contentBlock on ContentBlock {
    displayMode
    mode
    elevation
    eyebrow {
      ...eyebrow
    }
    title {
      ...titleText
    }
    text {
      ...text
    }
    cta {
      ...cta
    }
    ctaFunnel {
      ...funnelButton
    }
    form {
      ...form
    }
    ctaType
    ctaVisible
    desktopImage {
      url
      width
      height
      title
    }
    mobileImage {
      url
      width
      height
      title
    }
    listTitle {
      ...titleText
    }
    listText {
      ...text
    }
    list
    listCta {
      ...cta
    }
    listCtaType
    listCtaVisible
    desktopContentDirection
    mobileContentDirection
  }

  fragment imageBlock on ImageBlock {
    imageIsLink
    href
    linkTitle
    alignment
    playIcon
    elevation
    imageFit
    cta {
      ...cta
    }
    ctaVisible
    desktopImage {
      url
      title
      width
      height
    }
    tabletImage {
      url
      title
      width
      height
    }
    mobileImage {
      url
      title
      width
      height
    }
  }

  fragment textBlock on TextBlock {
    desktopAlignment
    mobileAlignment
    postCodeEngagerVisible
    eyebrow {
      ...eyebrow
    }
    title {
      ...titleText
    }
    text {
      ...text
    }
    ctaType
    ctaVisible
    cta {
      ...cta
    }
    funnel {
      ...funnelButton
    }
    postCodeEngagerVisible
    postCodeEngager {
      ...postCode
    }
  }

  fragment postCode on PostCodeEngager {
    elevated
    mode
    link
    desktopAlignment
    mobileAlignment
  }

  fragment eyebrow on EyeBrow {
    desktopAlignment
    mobileAlignment
    icon
    marginBottom
    children
    type
    mode
  }

  fragment titleText on TitleText {
    desktopAlignment
    mobileAlignment
    marginBottom
    size
    markdown: children
    mode
  }

  fragment text on Text {
    desktopAlignment
    mobileAlignment
    marginBottom
    size
    markdown: children
    mode
  }

  fragment cta on Button {
    children
    icon
    iconSize
    href
    mode
    size
    type
  }

  fragment funnelButton on FunnelButton {
    children
    type
    size
    url
  }

  fragment FAQS on Faqs {
    faqsCollection(limit: 6) {
      items {
        ...faqBox
      }
    }
  }

  fragment faqBox on FaqBox {
    url
    text
    image {
      url
      width
      height
      title
    }
    mode
  }

  fragment accordiansList on AccordiansList {
    eyebrow {
      ...eyebrow
    }
    title {
      ...titleText
    }
    about {
      ...text
    }
    accordiansCollection(limit: 15) {
      items {
        ...singleAccordian
      }
    }
    mode
    id
  }

  fragment singleAccordian on SingleAccordian {
    title
    bodyCollection(limit: 10) {
      items {
        ...accordianText
      }
    }
  }

  fragment accordianText on AccordianText {
    markdown: body
  }

  fragment cardVariantList on CardVariantList {
    mode
    eyebrow {
      ...eyebrow
    }
    titleText {
      ...titleText
    }
    text {
      ...text
    }
    cardVariantsCollection {
      items {
        ...cardVariant
      }
    }
  }

  fragment cardVariant on CardVariant {
    titleText {
      ...titleText
    }
    body {
      ...text
    }
    image {
      url
      width
      height
      title
    }
    mobileImage {
      url
      width
      height
      title
    }
    contentAlignment
    ctaVisible
    desktopImageWidth
    mobileImageWidth
    btnTextVisible
    elevated
    btnText
    cta {
      ...cta
    }
    background
    anchorId
  }

  fragment videoContainer on VideoContainer {
    eyebrow {
      ...eyebrow
    }
    headline {
      ...titleText
    }
    playBtnBg
    playBtnColor
    playText {
      ...text
    }
    relatedVideoText {
      ...titleText
    }
    cta {
      ...cta
    }
    backText {
      ...text
    }
    videoModalCollection(limit: 6) {
      items {
        ...videoModal
      }
    }
  }

  fragment videoModal on VideoModal {
    introTitle {
      ...titleText
    }
    introText {
      ...text
    }
    videoModalActive {
      ...videoModalActive
    }
    vid {
      ...BannerVideo
    }
    backgroundImage {
      url
      title
      width
      height
    }
    anchorId
  }

  fragment videoModalActive on VideoModalActive {
    text {
      ...text
    }
  }

  fragment updatedHomePageBanner on UpdatedHomePageBanner {
    placeholder
  }

  fragment articleBlock on ArticleBlock {
    duration
    durationText
    date
    ctasCollection(limit: 3) {
      items {
        ...cta
      }
    }
    textCollection(limit: 3) {
      items {
        ...textBlock
      }
    }
    imgOne {
      url
      width
      height
      title
    }
    imgTwo {
      url
      width
      height
      title
    }
    imgThree {
      url
      width
      height
      title
    }
  }

  fragment formInputs on FormTextInputs {
    fieldLabel
    fieldName
    fieldType
    optional
    optionalText
    placeHolderIcon
    placeHolderText
    helperText
    mode
  }

  fragment form on Form {
    bg
    eyebrow {
      ...eyebrow
    }
    formTitle {
      ...titleText
    }
    disclaimer {
      ...text
    }
    disclaimerOnSide
    mode
    desktopPaddingTop
    desktopPaddingBottom
    mobilePaddingBottom
    mobilePaddingTop
    elevation
    leadSource
    successPageUrl
    failurePageUrl
    textInputsCollection(limit: 20) {
      items {
        ...formInputs
      }
    }
  }
  `;
}

/* GraphQL */ gqlmin(PageContentQuery('/'));
